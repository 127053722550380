import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { Layout, Button, Row, Col } from 'antd';
import Header from '../components/PageLayout/Header';
import SidebarWrapper from '../components/PageLayout/Sidebar';
import SEO from '../components/Seo';

export default class Resume extends Component {
  constructor() {
    super();
    this.state = {
      numPages: null,
      pageNumber: 1,
    };
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const pageToggle = () => {
      if (pageNumber === 1) {
        this.setState({ pageNumber: 2 });
      } else {
        this.setState({ pageNumber: 1 });
      }
      return 1;
    };

    return (
      <Layout className="outerPadding">
        <Layout className="container">
          <Header />
          <SEO
            title="Resume"
            description="A page that contains my resume"
            path="resume"
          />
          <SidebarWrapper>
            <div className="resumeSectionLeft">
              <h2>Professional history</h2>

              <h3>Application Engineer,
                {' '}
                <b>the LEGO group</b>
              </h3>
              <ul style={{marginTop: '8px', marginBottom: '16px'}}>
                <li>Extending shoppable markets as part of the Cart team.</li>
                <li>Creating a brand new application for Customer Services Advisors to use when placing orders (NextJS, React, TypeScript, TanStack).</li>
                <li>All using AWS Fargate and CDK to deploy docker containers and various other AWS services to mock data (e.g. Lambda, API Gateway).</li>
                <li>Setup monitoring using Elastic stack and AWS Cloudwatch.</li>
                <li>All built with good dev practices (TDD, Trunk Based Development, Pair Programming).</li>
                <li>Mentoring junior developers by having 1 to 1 meetings and pairing sessions and contributing to the LEGO groups blog and culture with various Tech articles.</li>
              </ul>
              
              <h3>Fullstack software engineer,
                {' '}
                <b>giffgaff</b>
                </h3>
                <ul style={{marginTop: '8px'}}>
                  <li>Developing features for giffgaff's Android app</li>
                  <li>Building Java micro services in Spring framework</li>
                  <li>Building new web experiences for giffgaff members 
                    (NextJS, React JS, React Hooks, xState, React Testing Lib, Cypress, Formik and more)</li>
                  <li>Tutoring any new team members through writing documentation and pair programming</li>
                  <li>All built with good dev practices (TDD, Trunk Based Development, Pair Programming, Lean UX)</li>
                  <li>All using AWS EKS to deploy docker containers (Next JS apps and Java microservices)</li>
                </ul>
              </div>
            <div className="resumeSectionRight">
              <h2>Project work</h2>
              <div>
                <h3>
                  <b>the LEGO group</b>
                </h3>
                  <ul style={{marginTop: '8px', marginBottom: '12px'}}>
                    <li>Implementing checkout for countries such as Romania, Korea and Mexico</li>
                    <li>Creating and delivering a brand new, large scale, internal application 
                      for our Customer Service Advisors to use, in multiple countries</li>
                  </ul>
                </div>
              <div>
                <h3>
                  <b>giffgaff</b>
                </h3>
                  <ul style={{marginTop: '8px', marginBottom: '12px'}}>
                    <li>Rewriting authentication flow from PHP Monolith to NextJS frontend microapp</li>
                    <li>Implementing multi factor authentication and email verification 
                      on both backend Java Spring microservice and frontend NextJS microapp</li>
                    <li>Creating and publishing the frontend microapp for giffgaff's "Goodybank" initiative</li>
                    <li>Implementing new authentication flow in giffgaff's hybrid React-Native mobile app</li>
                  </ul>
                </div>
              <div>
                <h3>
                  <b>Freelance</b>
                </h3>
                <ul style={{marginTop: '8px'}}>
                  <li>PsyBreaker - a Brain-Computer Interface game replica of Atari's Breakout</li>
                  <li>A Developer's Adventure - 2D Platformer game made as 
                    part of applying to Rovio's Games Department</li>
                  <li>Noughts and Crosses - simple noughts and crosses 
                    game developed in Java Swing with smart AI that tries to counter each move</li>
                </ul>
              </div>
            </div>
          </SidebarWrapper>
        </Layout>
      </Layout>
    );
  }
}
